<template>
  <v-container class="py-7">
    <div class="heading mb-7" @click="count++">Настройки профиля</div>

    <div class="mb-8">
      <v-sheet
        class="custom-field"
        v-ripple="{ class: 'secondary--text' }"
        @click="$router.push({ name: 'UserSettingsProfile' })"
      >
        <span>Редактировать профиль</span>
        <v-icon color="secondary" size="32" class="mr-n2 ml-auto">{{
          icons.mdiChevronRight
        }}</v-icon>
      </v-sheet>

      <v-sheet
        class="custom-field"
        v-if="!alternativeAuth"
        v-ripple="{ class: 'secondary--text' }"
        @click="$router.push({ name: 'UserSettingsPassword' })"
      >
        <span>Изменить пароль</span>
        <v-icon color="secondary" size="32" class="mr-n2 ml-auto">{{
          icons.mdiChevronRight
        }}</v-icon>
      </v-sheet>

      <!-- <v-sheet class="custom-field" v-ripple="{ class: 'secondary--text' }">
        <span>Выбрать язык приложения</span>
        <v-icon color="secondary" size="32" class="mr-n2 ml-auto">{{icons.mdiChevronRight}}</v-icon>
      </v-sheet>-->
    </div>

    <div class="mb-8" v-if="count > 10">fcmToken: {{ fcmToken }}</div>

    <v-btn
      class="mb-8"
      depressed
      block
      color="primary"
      x-large
      @click="$store.commit('user/logout')"
      >Выйти из учетной записи</v-btn
    >

    <v-btn color="error" depressed block outlined @click="deleteDialog = true"
      >Удалить аккаунт</v-btn
    >

    <v-dialog v-model="deleteDialog" persistent max-width="300">
      <v-card>
        <v-card-title> Вы действительно хотите удалить аккаунт? </v-card-title>
        <v-card-text class="text-subtitle-1"
          >Персональные данные, история заказов и бонусные баллы будут
          безвозвратно удалены.</v-card-text
        >

        <v-card-text v-if="timerActive" class="text-subtitle-1">
          Удаление аккаунта...
          <div class="d-inline-block mb-2">
            <div class="seconds">
              {{ timer }}
            </div>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close"> Отменить </v-btn>
          </v-card-actions>
        </v-card-text>

        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="startCountdown"> Удалить </v-btn>
          <v-btn color="primary" text @click="close"> Отмена </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'

export default {
  name: 'Settings',

  data: () => ({
    icons: {
      mdiChevronRight,
    },
    count: 0,

    deleteDialog: false,
    timerActive: false,
    timer: 10,
    intervalId: null,
  }),

  computed: {
    alternativeAuth() {
      return this.$store.getters['app/alternativeAuth']
    },

    fcmToken() {
      return this.$store.state.app.fcmToken
    },

    merchantKey() {
      return this.$store.getters['aggregator/key']
    },

    token() {
      return this.$store.getters['user/token']
    },
  },

  methods: {
    close() {
      this.deleteDialog = false
      clearInterval(this.intervalId)
      setTimeout(() => {
        this.timerActive = false
        this.timer = 10
      }, 300)
    },

    async onDelete() {
      const { commit } = this.$store
      commit('overlay/update', { active: true })
      try {
        await this.$api.get('removeAccount', {
          params: { merchantKeys: this.merchantKey, token: this.token },
        })
        commit('user/logout')
        this.$store.commit('snackbar/update', {
          active: true,
          text: 'Аккаунт удален',
        })
        this.close()
        this.$router.push('/')
      } catch (error) {
        this.$store.commit('snackbar/update', {
          active: true,
          text: error.message,
        })
      } finally {
        commit('overlay/update', { active: false })
      }
    },

    startCountdown() {
      this.timerActive = true
      this.intervalId = setInterval(() => {
        this.timer -= 1
        if (this.timer === 0) {
          this.close()
          this.onDelete()
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.seconds {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-left: 10px;
  border-radius: 50%;
  background: var(--v-primary-base);
  color: white;
}
</style>
